var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      staticClass: "mb-0 navbar-wrapper",
      staticStyle: { "background-color": "white" },
      attrs: { height: "80", "elevation-0": "" },
    },
    [
      _c("v-toolbar-title", [
        _c("span", { style: { "margin-left": _vm.screenSize.logoMargin } }),
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                {
                  _vm.process.env.VUE_APP_BASEURL
                }
              },
            },
          },
          [
            _c("img", {
              staticStyle: { cursor: "pointer" },
              attrs: { src: "/img/profile/grupa-logo.png", width: "80" },
              on: { click: _vm.handleHome },
            }),
          ]
        ),
      ]),
      _c("v-spacer"),
      _vm.$route.name !== "login"
        ? _c("span", [
            _vm.currentUser && _vm.currentUser.name
              ? _c("span", [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.currentUser.name.split(" ")[0]) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.currentUser && _vm.currentUser.name
        ? _c(
            "v-menu",
            {
              style: { "margin-right": _vm.screenSize.margin_right },
              attrs: {
                bottom: "",
                origin: "center center",
                transition: "scale-transition",
              },
            },
            [
              _c(
                "v-btn",
                {
                  attrs: { slot: "activator", flat: "", icon: "" },
                  slot: "activator",
                },
                [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                1
              ),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-tile",
                    [
                      _c(
                        "v-list-tile-title",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.handleLogout },
                        },
                        [_vm._v("Logout")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-toolbar-items", { staticClass: "hidden-sm-and-down" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }