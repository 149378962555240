<template>
  <v-toolbar
    class="mb-0 navbar-wrapper"
    height="80"
    elevation-0
    style="background-color: white"
  >
    <v-toolbar-title>
      <span v-bind:style="{ 'margin-left': screenSize.logoMargin }"></span>
      <!-- <router-link :to="{ name: 'login'}"> -->
     <a
        @click="
          {
            process.env.VUE_APP_BASEURL;
          }
        "
        ><img
          src="/img/profile/grupa-logo.png"
          width="80"
          style="cursor: pointer"
          @click="handleHome"
      /></a>
      <!-- </router-link> -->
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- || $route.name !== 'forgot_password' -->
    <span v-if="$route.name !== 'login'">
      <span v-if="currentUser && currentUser.name">
        {{ currentUser.name.split(" ")[0] }}
      </span>
      <!-- <span v-else class="text-lg-right">user@email.com</span> -->
    </span>
    <v-menu
      bottom
      origin="center center"
      transition="scale-transition"
      v-bind:style="{ 'margin-right': screenSize.margin_right }"
      v-if="currentUser && currentUser.name"
    >
      <v-btn flat slot="activator" icon>
        <v-icon>keyboard_arrow_down</v-icon>
      </v-btn>
      <v-list>
        <v-list-tile>
          <v-list-tile-title style="cursor: pointer" @click="handleLogout"
            >Logout</v-list-tile-title
          >
        </v-list-tile>
      </v-list>
    </v-menu>
    <v-toolbar-items class="hidden-sm-and-down"></v-toolbar-items>
  </v-toolbar>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import { logoutUser } from "@/utils/logoutUser";
export default {
  data: () => ({
    name: null,
  }),
  mounted() {},
  methods: {
    handleHome() {
      // window.location.href = `${process.env.VUE_APP_SPLASHPAGE}`;
    },
    handleLogout() {
      logoutUser();
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { logoMargin: "0px", margin_right: "0px" };
        case "sm":
          return { logoMargin: "0px", margin_right: "0px" };
        case "md":
          return { logoMargin: "50px", margin_right: "50px" };
        case "lg":
          return { logoMargin: "100px", margin_right: "100px" };
        case "xl":
          return { logoMargin: "100px", margin_right: "100px" };
      }
    },
  },
};
</script>
<style scoped>
.navbar-wrapper {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
  /* position: fixed; */
}
</style>
